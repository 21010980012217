import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { OrderListApi } from "services/OrderService";

export const orderList = createAsyncThunk(
  "order/orderList",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await OrderListApi(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = {
  list: [],
  isNext: false,
  loading: false,
  orderDetails: null
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    selectedOrder: (state, action) => {
      state.orderDetails = action.payload
    },
  },
  extraReducers: (builder) => {
    // Order List
    builder
      .addCase(orderList.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.isNext = action.payload.isNext;
      })
      .addCase(orderList.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { selectedOrder } = orderSlice.actions;

export default orderSlice.reducer;
