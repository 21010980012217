import axiosInstance from "./axiosInstance";

// Login user
export const login = async (credentials) => {
  const response = await axiosInstance.post("/auth/login", credentials);
  return response.data;
};

// IsLoggedIn user
export const isLoggedIn = async () => {
  const response = await axiosInstance.get(`/auth/isloggedin`);
  return response.data;
};

// Logout user
export const logout = async () => {
  const response = await axiosInstance.get(`/auth/logout`);
  return response.data;
};

// Profile update
export const profileUpdateApi = async (data) => {
  const response = await axiosInstance.post("/auth/edit-profile", data);
  return response.data;
};