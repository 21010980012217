import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
];

export const protectedRoutes = [
  // User Routes
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/user/list`,
    component: React.lazy(() => import("views/app-views/users/list")),
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/user/add`,
    component: React.lazy(() => import("views/app-views/users/add")),
  },
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/user/update/:id`,
    component: React.lazy(() => import("views/app-views/users/edit")),
  },

  // Order Routes
  {
    key: "order",
    path: `${APP_PREFIX_PATH}/order/list`,
    component: React.lazy(() => import("views/app-views/order/list")),
  },
  {
    key: "order",
    path: `${APP_PREFIX_PATH}/order/view/:id`,
    component: React.lazy(() => import("views/app-views/order/view")),
  },

  // Product Routes
  {
    key: "products",
    path: `${APP_PREFIX_PATH}/product/list`,
    component: React.lazy(() => import("views/app-views/product/product-list")),
  },
  {
    key: "products",
    path: `${APP_PREFIX_PATH}/product/view/:id`,
    component: React.lazy(() => import("views/app-views/product/edit-product")),
  },
  
  // Profile Routes
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import("views/app-views/profile")),
  },
];
