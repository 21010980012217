import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserPriceSheetApi, UserDetailApi, UserListApi } from "services/UserService";

export const userList = createAsyncThunk(
  "user/userList",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await UserListApi(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/userGetDetails",
  async (values, { rejectWithValue }) => {
    try {
      const response = await UserDetailApi(values);
      return await response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserPriceSheet = createAsyncThunk(
  "user/userPriceSheet",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await getUserPriceSheetApi(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const initialState = {
  list: [],
  isNext: false,
  loading: false,
  userDetails: {
    data: {},
    loading: false,
    error: null,
  },
  priceSheet: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // User List
    builder
      .addCase(userList.pending, (state) => {
        state.loading = true;
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.isNext = action.payload.isNext;
      })
      .addCase(userList.rejected, (state, action) => {
        state.loading = false;
      });

    // User Edit
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.userDetails.loading = true;
        state.error = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userDetails.loading = false;
        if (!action.payload.success) state.error = action.payload.message;
        state.userDetails.data = action.payload.data;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.userDetails.loading = false;
        if (!action.payload.success) state.error = action.payload.message;
      });

    // user price sheet
    builder
      .addCase(getUserPriceSheet.pending, (state) => {})
      .addCase(getUserPriceSheet.fulfilled, (state, action) => {
        state.priceSheet = action.payload.data;
      })
      .addCase(getUserPriceSheet.rejected, (state, action) => {});
  },
});

// export const {} = userSlice.actions;

export default userSlice.reducer;
