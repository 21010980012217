import axiosInstance from "./axiosInstance";

// products list
export const ProductListApi = async (data) => {
  const response = await axiosInstance.get("/product", { params: data });
  return response.data;
};

// product get details
export const ProductDetailApi = async (id) => {
  const response = await axiosInstance.get(`/product/${id}`);
  return response.data;
};

// product categories
export const ProductCategoriesApi = async (data) => {
  const response = await axiosInstance.get(`/product/product-type`, { params: data });
  return response.data;
};
