import axiosInstance from "./axiosInstance";

// order list
export const OrderListApi = async (data) => {
  const response = await axiosInstance.get("/order", { params: data });
  return response.data;
};

// order update
export const OrderUpdateApi = async (data) => {
  const response = await axiosInstance.put(`/order/${data._id}`, data);
  return response.data;
};
