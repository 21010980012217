import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ProductCategoriesApi, ProductDetailApi, ProductListApi } from "services/ProductService";

export const productList = createAsyncThunk(
  "product/productList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductListApi(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductDetails = createAsyncThunk(
  "product/productDetails",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ProductDetailApi(values);
      return await response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const productCategoriesThunk = createAsyncThunk(
  "product/productCategories",
  async (values, { rejectWithValue }) => {
    try {
      const response = await ProductCategoriesApi(values);
      return await response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const initialState = {
  list: [],
  isNext: false,
  loading: false,
  productDetails: {
    data: {},
    loading: false,
    error: null,
  },
  productCategories: {
    list: [],
    loading: false,
    isNext: false,
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Product List
    builder
      .addCase(productList.pending, (state) => {
        state.loading = true;
      })
      .addCase(productList.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.isNext = action.payload.isNext;
      })
      .addCase(productList.rejected, (state, action) => {
        state.loading = false;
      });

    // Product get details
    builder
      .addCase(getProductDetails.pending, (state) => {
        state.productDetails.loading = true;
        state.error = null;
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.productDetails.loading = false;
        if (!action.payload.success) state.error = action.payload.message;
        state.productDetails.data = action.payload.data;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.productDetails.loading = false;
        if (!action.payload.success) state.error = action.payload.message;
      });

    // Product Categories
    builder
      .addCase(productCategoriesThunk.pending, (state) => {
        state.productCategories.loading = true
      })
      .addCase(productCategoriesThunk.fulfilled, (state, action) => {
        state.productCategories.loading = false
        state.productCategories.list = action.payload.data;
        state.productCategories.isNext = action.payload.isNext;
      })
      .addCase(productCategoriesThunk.rejected, (state, action) => {
        state.productCategories.loading = false
      });
  },
});

// export const {} = userSlice.actions;

export default productSlice.reducer;
