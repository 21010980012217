import axiosInstance from "./axiosInstance";

// user list
export const UserListApi = async (data) => {
  const response = await axiosInstance.get("/user", { params: data });
  return response.data;
};

// user add
export const UserAddApi = async (data) => {
  const response = await axiosInstance.post(`/user`, data);
  return response.data;
};

// user edit
export const UserEditApi = async (data) => {
  const response = await axiosInstance.put(`/user/${data._id}`, data);
  return response.data;
};

// user get details
export const UserDetailApi = async (id) => {
  const response = await axiosInstance.get(`/user/${id}`);
  return response.data;
};

// upload user price sheet
export const UploadPriceSheetApi = async (data) => {
  const response = await axiosInstance.post(`/user-price/uploadxls`, data);
  return response.data;
};

// get user price sheet
export const getUserPriceSheetApi = async (data) => {
  const response = await axiosInstance.get(`/user-price`, { params: data });
  return response.data;
};

// get user price sheet
export const downloadPriceSheetApi = async (data) => {
  const response = await axiosInstance.get(`/user-price/download-xls`, {
    params: data,
    responseType: "blob",
  });
  return response.data;
};
