import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { isLoggedIn, login, logout } from "services/AuthService";

export const initialState = {
  user: null,
  logged: localStorage.getItem("logged") || null,
  isAuthenticated: false,
  loading: false,
  error: null,
  message: "",
};

// Thunk for login
export const signIn = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await login(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LoggedInCheck = createAsyncThunk(
  "auth/checkIsLoggedIn",
  async (_, { rejectWithValue }) => {
    try {
      const data = await isLoggedIn();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      await logout(); // Call the logout API
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.logged = null;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle login
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        localStorage.setItem("logged", "wait");
        if (action.payload.message) {
          if (action.payload.success) {
            message.success(action.payload.message);
          } else {
            message.error(action.payload.message);
          }
        }
      })
      .addCase(signIn.rejected, (state, action) => {
        state.error = action.payload.message;
        state.loading = false;
      });

    // Handle IsLoggedIn
    builder.addCase(LoggedInCheck.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.logged = "wait";
    });
    builder.addCase(LoggedInCheck.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.isAuthenticated = true;
        state.logged = "pass";
        localStorage.setItem("logged", "pass");
      } else {
        state.isAuthenticated = false;
        state.logged = "failed";
        localStorage.setItem("logged", "failed");
      }
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(LoggedInCheck.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.logged = "failed";
    });

    // Handle logout
    builder.addCase(signOut.fulfilled, (state) => {
      state.user = null;
      state.logged = "failed";
      state.isAuthenticated = false;
      localStorage.setItem("logged", "failed");
      state.loading = false;
    });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
